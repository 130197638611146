@font-face {
  font-family: "halisr-book";
  src: url('//www.bears-with-benefits.com/cdn/shop/t/121/assets/halisr-book.otf?v=7904961602062477851652271382')
  format("opentype"),
  url('//www.bears-with-benefits.com/cdn/shop/t/121/assets/halisr-book.woff2?v=91642422919270254951652271387')
  format("woff2"),
  url('//www.bears-with-benefits.com/cdn/shop/t/121/assets/halisr-book.woff?v=81921238766281154831652271380')
  format("woff"),
  url('//www.bears-with-benefits.com/cdn/shop/t/121/assets/halisr-book.ttf?v=72418681975171076051652271387')
  format("truetype");
  font-display: swap;
  }

body {
  margin: 0;
  font-family: "halisr-book", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: small;
}

.clickable-item {
  cursor: pointer;
}

h1 {
  font-size: 1.5em;
  margin: 0;
  padding: 0;
}

.outer-container {
  width: 100%;
  display: inline-block;
}



.dialog {
  background-color: #fff;
  display: block;
  margin: 10px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.4);

}

.dialog .head {
  position: sticky;
  top: 12px;
  background-color: #fff;
  border-radius: 12px 12px 0px 0px;

  display: block;
  z-index: 50;
  padding: 10px 20px 0px 10px;
  border-bottom: 0px solid #ddd;
  min-height: 72px;
}

.dialog .head h1 {
  font-size: 1.25em;
  font-weight: 600;
  margin: 0;
}

.dialog .head img {
  float: left;
  height: 64px;
  margin-right: 20px;
}

.dialog .content {
  overflow-y: auto;
  max-height: 80vh;
}

.dialog .footer {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  border-radius: 0px 0px 12px 12px;
  display: block;
  z-index: 50;
  padding: 10px 20px 10px 10px;
  border-top: 1px solid #ddd;
  text-align: right;
  font-size: smaller;
  align-items: center;

}

.dialog .footer span {
  position: relative;
  top: -0.15em;
  margin-right: 0.3em;
}

.dialog .footer img {
  height: 11px;
  margin-right: 20px;
  padding-top: 5px;
}

.proofpoint-image {
  height: 40px;
  width: 40px;
  margin-right: 8px;
}

.product-image {
  height: 40px;
  margin-left: 8px;
  float: right;
}

.dialog .details h3 {
  margin-bottom: 5px;
  color: #505050;
  font-style: italic;
}

.dialog .details .MuiCard-root {
  padding: 5px;
}




/* Styles for tablets and below */
@media only screen and (max-width: 968px) {
  .dialog {
    background-color: #fff;
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0px;
    margin: 0px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.4);

  }

  .dialog .content {
    overflow-y: auto;
  

  }
}

/* Styles for mobile phones */
@media only screen and (max-width: 600px) {
  .container {
    font-size: 9px;
    padding: 10px;
  }


  .head {
    display: none;

  }

  .tabs {
    display: none;
  }
}


.mobile-outer-container {
  width: 100%;
  display: inline-block;
  flex-direction: row;
  margin-top: 0px;
  

}

.mobile-container {
  background-color: #fff;
  margin-top: 0px;
  border-radius: 5px;

}

.mobile-proofpoint-image {
  height: 32px;
  margin-right: 10px;
  float: left;
}

.mobile-info-image {
  height: 32px;
  margin-left: 10px;
  float: right;

}

.mobile-head {
  position:sticky;
  top: 0;
  background-color: #fff;
  display: 'flex';
  flex:1;
  flex-direction: 'row';
  align-items: 'center';
  z-index: 50;
  padding: 8px;
  border-bottom: 0px solid #ddd;
  min-height: 32px;
}

.mobile-product-image {
  height: 48px;
  margin-right: 10px;
  float: left;

}


.mobile-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #fff;
  border-radius: 0px 0px 5px 5px;
  display: block;
  z-index: 50;
  padding: 10px 20px 10px 10px;
  border-top: 1px solid #ddd;
  text-align: right;
  align-items: center;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  display: flex;
  padding: 10px;
  border-top: 1px solid #ddd;
  text-align: right;

}
.mobile-footer img {
  height: 10px;
  margin-right: 20px;
  padding-top: 0px;
  margin-left: 4px;
}

.mobile-modal-text {
  font-size: 10pt;
}